import React from 'react'
import Sidebar from '../Sidebar'
import './style.scss'

import Helmet from 'react-helmet'

class PageTemplateDetails extends React.Component {
  render() {
    const page = this.props.data.markdownRemark
    const featuredImgSize = page.frontmatter.featuredImage.childImageSharp.sizes.src
    const { description: pageDescription } = page.frontmatter
    const path = page.frontmatter
    const pagepath = `https://kinnikumegane.com/${path}`
    const image = `https://kinnikumegane.com/${featuredImgSize}`;

    return (
      <div>
        <Helmet>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={page.frontmatter.title} />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:description" content={pageDescription} />
          <meta name="twitter:site" content="@KinnikuMeganeDe" />
          
          <meta property="og:title" content={page.frontmatter.title} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={image} />
          <meta property="og:url" content={pagepath} />
          <meta property="og:description" content={pageDescription} />
        </Helmet>
        {/* commented out by Kinniku <Sidebar {...this.props} /> */}
        <div className="content">
          <div className="content__inner">
            <div className="page">
              <h1 className="page__title">{page.frontmatter.title}</h1>
              <div
                className="page__body"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: page.html }}
              />
            </div>
            <h3 className="sharesuru">シェアする</h3>
            {/* add SNS button*/}
            <a className="twitter-share-button" data-size="large" href="https://twitter.com/share?ref_src=twsrc%5Etfw" data-show-count="false">Tweet</a>
          </div>
        </div>
        <Sidebar {...this.props} />
      </div>
    )
  }
}

export default PageTemplateDetails